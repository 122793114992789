export const carouselData = [
    {
        title: 'example',
        desc: '',
        img: './images/portfolio/pm.jpg',
        alt: '',
    },
    {
        title: 'example2',
        desc: '',
        img: './images/portfolio/safc1.jpg',
        alt: '',
    },
    {
        title: 'example3',
        desc: '',
        img: './images/portfolio/rc.jpg',
        alt: '',
    },
    
]